<template>
  <v-dialog v-model="modal.toggle" max-width="500" persistent>
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="modal.loading"
          :isDelete="true"
          :title="category.name"
          @onClose="closeModal()"
        />

        <v-row style="justify-content: center;">
          <v-col md="8" style="text-align: center;">
            <h4>
              Atribuir os lançamentos da categoria
              <strong>{{category.name}}</strong>
              para:
            </h4>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-autocomplete
              v-model="toId"
              label="Nova categoria"
              append-icon="mdi-magnify"
              item-text="name"
              item-value="id"
              :items="categories"
              :error-messages="toIdErrors"
              @blur="$v.toId.$touch()"
              clearable
              outlined
              filled
              dense>
              <template v-slot:item="data">
                <v-list-item-avatar size="24px" :style="`background: ${data.item.colour};`"> </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="secondary"
              style="width: 100%;"
              :loading="modal.loading"
              @click="excluirCategoria()">
              Confirmar
            </v-btn>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="error"
              style="width: 100%;"
              :loading="modal.loading"
              @click="closeModal()">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import validation from '../validation'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

const namespaceStore = 'organizzeFinanceiroCategorias'

export default {
  name: 'OrganizzeFinanceiroCategoriasModalExcluir',

  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header')
  },

  data: (() => ({
    modal: {
      toggle: false,
      loading: false
    },
    category: {},
    toId: null
  })),

  computed: {
    ...mapGetters(namespaceStore, ['categories']),
    ...validation
  },

  mounted () {
    const self = this

    Events.$on('modalExcluir::open', data => {
      self.modal.toggle = true
      self.category = data.category
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['deleteCategory']),

    excluirCategoria () {
      const self = this

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      self.modal.loading = true
      self.deleteCategory({ id: self.category.id, toId: self.toId }).then(() => {
        successSnackbar('Categoria excluída com sucesso.')
        Events.$emit('index::getCategories')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.modal.loading = false
      })
    },

    closeModal () {
      const self = this

      self.modal = {
        toggle: false,
        loading: false
      }
      self.category = {}
      self.toId = null
    },
  },

  validations () {
    return {
      toId: { required }
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }
</style>